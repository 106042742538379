import React from "react";
import LocalStorageService from "../../helper/localstorage-services";
import { Navigate } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";

const RouteAuthGuardRestrictLoggedUser = ({ children }) => {
  const token = LocalStorageService.getLoggedInUserToken();
  const userDetails = LocalStorageService.getLoggedInUserDetails();
  if (token) {
    return (
      <Navigate
        to={userDetails?.name ? ROUTE_URLS.MY_ACCOUNT : ROUTE_URLS.USER_NAME}
        replace
      />
    );
  }
  return children;
};

export default RouteAuthGuardRestrictLoggedUser;
