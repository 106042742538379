import React, { useState } from "react";
import { Container, Modal, Toast } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import copyImg from "../../assets/images/copyImg.svg";
import LocalStorageService from "../../helper/localstorage-services";

const InviteFriends = ({ showInvite, setShowInvite, tripCode }) => {
  const [show, setShow] = useState(false);
  const { name } = LocalStorageService.getLoggedInUserDetails();

  const copyFunction = () => {
    let copyText = document.getElementById("copyLink");
    navigator?.clipboard?.writeText(copyText.innerHTML);
    setShow(true);
  };

  return (
    <>
      <Modal
        show={showInvite}
        fullscreen={true}
        onHide={() => setShowInvite(false)}
        animation={false}
      >
        <Modal.Body className="p-0">
          <Container>
            <div className="wprcontainer">
              <div
                onClick={() => {
                  setShowInvite(false);
                }}
                style={{ right: "0", zIndex: "1", top: "35px", left: "auto" }}
                className="position__Arrow blueBackground pointer"
                id="close-button-invite"
              >
                <img src={close} alt="close" />
              </div>
              <div style={{ height: "10px", marginTop: "65px" }}>
                {show && (
                  <Toast
                    onClose={() => setShow(false)}
                    show={show}
                    delay={3000}
                    autohide
                    bg={"success"}
                  >
                    <Toast.Body className="text-white">
                      Successfully Copied
                    </Toast.Body>
                  </Toast>
                )}
              </div>
              <div className="emailContainer">
                <h6 className="golbalText pb-4">Invite your friends!</h6>
                <p className="pb-3">
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      display: "block",
                    }}
                  >
                    Porridge is better shared with friends.
                  </span>
                  Use this link to invite others to help pick your something fun
                  to do. They’ll see the same activities you do, and your
                  combined votes will rank them.
                </p>
                <div className="invite-box">
                  <p id="copyLink">
                    https://getporridge.com/invite/{name?.replace(/\s+/g, "-")}_
                    {tripCode}
                  </p>
                  <div
                    onClick={() => copyFunction()}
                    className="copyBg detailBox pointer"
                    id="copy-invite-url"
                  >
                    <img src={copyImg} alt="copyImage" />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteFriends;
