import React, { useEffect, useLayoutEffect } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import { useState } from "react";
import TinderCard from "react-tinder-card";
import Overlayimg from "../../assets/images/overlayimg.svg";
import disLike from "../../assets/images/dislike.svg";
import close from "../../assets/images/close.svg";
import like from "../../assets/images/like.svg";
import blackHeart from "../../assets/images/blackHeart.svg";
import PropertyDetails from "../../components/modals/PropertyDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  addDisLikeProperty,
  addLikeProperty,
  getAllDestinationProduct,
  updateSwpieCurrntPage,
} from "../../middleware/trip";
import LocalStorageService from "../../helper/localstorage-services";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";

const PropertyCards = ({ userInfo }) => {
  const {
    oneTripDetail,
    allDestinationProduct,
    allDestinationProductPagination,
    allDestinationProductLoading,
  } = useSelector((state) => state.trip);
  const nav = useNavigate();
  const params = useParams();
  const [propertyData, setPropertyData] = useState([]);
  const [showGuide, setGuideShow] = useState(true);
  const [showDetailProperty, setShowDetailProperty] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(propertyData?.length - 1);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [nameToDelete, setNametoDelete] = useState(null);
  const dispatch = useDispatch();
  const { user_id, name } = LocalStorageService.getLoggedInUserDetails();
  const [page, setPage] = useState(userInfo?.lastCount);
  const [count, setCount] = useState(userInfo?.lastCount - 1);

  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  useEffect(() => {
    const swipGuide = localStorage.getItem("swipe-guide");
    if (!swipGuide) {
      setGuideShow(true);
    } else {
      setGuideShow(false);
    }
  }, []);

  useEffect(() => {
    if (
      !allDestinationProductLoading &&
      allDestinationProduct &&
      allDestinationProduct.length > 0
    ) {
      const tripInfo = JSON.parse(JSON.stringify(allDestinationProduct));
      setPropertyData(tripInfo);
      setCurrentIndex(tripInfo.length - 1);
    }
    // eslint-disable-next-line
  }, [allDestinationProduct]);

  useEffect(() => {
    if (oneTripDetail && !Array.isArray(oneTripDetail)) {
      const payload = {
        destinationId: oneTripDetail.destinationId,
        checkIn: oneTripDetail.checkIn,
        checkOut: oneTripDetail.checkOut,
        // durationMinutes: oneTripDetail.durationMinutes,
        startPagination: page.toString(),
        countPagination: "10",
      };
      // if (
      //   allDestinationProductPagination === null ||
      //   (allDestinationProductPagination !== null &&
      //     parseInt(allDestinationProductPagination.start) !== page)
      // ) {
      dispatch(getAllDestinationProduct(payload));
      // }
    }
    // eslint-disable-next-line
  }, [page]);

  const childRefs = useMemo(
    () =>
      Array(propertyData?.length)
        .fill(0)
        .map((i) => React.createRef()),
    // eslint-disable-next-line
    [currentIndex]
  );

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val);
    currentIndexRef.current = val;
  };

  useLayoutEffect(() => {
    if (nameToDelete !== null) {
      const payload = {
        productCode: nameToDelete.productCode,
        title: nameToDelete.title,
        duration: nameToDelete.duration,
        description: nameToDelete.description,
        pricing: nameToDelete.pricing,
        destinations: nameToDelete.destinations,
        productUrl: nameToDelete.productUrl,
        user_id: user_id,
        name: name,
      };
      dispatch(
        updateSwpieCurrntPage(oneTripDetail.tripCode, {
          user_id: userInfo.user_id,
          lastCount: count,
        })
      );
      if (swipeDirection === "right") {
        dispatch(addLikeProperty(oneTripDetail.tripCode, payload));
      } else if (swipeDirection === "left") {
        dispatch(
          addDisLikeProperty(oneTripDetail.tripCode, {
            user_id: payload.user_id,
            name: name,
            productCode: payload.productCode,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [nameToDelete]);

  const swiped = (direction, index) => {
    setSwipeDirection(direction);
    updateCurrentIndex(index - 1);
  };

  const canSwipe = currentIndex >= 0;

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < propertyData?.length && !showGuide) {
      await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
    }
  };

  const outOfFrame = (
    dir,
    idx,
    index,
    title,
    duration,
    description,
    pricing,
    destinations,
    productUrl
  ) => {
    propertyData.splice(index, 1);
    setCount(count + 1);
    const payload = {
      productCode: idx,
      title: title,
      duration: duration,
      description: description,
      pricing: pricing,
      destinations: destinations,
      productUrl: productUrl,
      index: index,
    };
    if (nameToDelete?.productCode !== idx) {
      setNametoDelete(payload);
    }
    if (index === 0) {
      setPage(parseInt(allDestinationProductPagination.start) + 10);
    }
    window.dataLayer.push({
      event: "swipe_direction",
      eventProps: {
        direction: `swipe-${dir}`,
      },
    });
  };

  return (
    <>
      {allDestinationProductLoading ? (
        <Spinner
          style={{
            color: "#ffc533",
          }}
          animation='border'
          variant='warning'
        />
      ) : (
        <>
          {propertyData && propertyData?.length < 1 ? (
            <div className='emailContainer'>
              <h6 className='golbalText pb-4 mb-0'>
                You’ve run out of available activities!
              </h6>

              <button
                onClick={() => {
                  nav(`${ROUTE_URLS.HOLIDAY_LOCATION}/${params.tripCode}`);
                }}
                type='button'
                className='golbalbtn'
              >
                Add another location
              </button>
            </div>
          ) : (
            <>
              <div className='cardContainer'>
                {showGuide && (
                  <div className='overlay_sec'>
                    <img
                      src={Overlayimg}
                      alt='overlayimg'
                    />
                    <button
                      onClick={() => {
                        setGuideShow(false);
                        localStorage.setItem("swipe-guide", false);
                      }}
                      type='button'
                      className='golbalbtn gotItbtn'
                    >
                      Got it!
                    </button>
                  </div>
                )}
                {propertyData &&
                  propertyData?.length > 0 &&
                  propertyData?.map((character, index) => (
                    <div
                      className={`${showGuide && "blurDiv"}`}
                      key={index}
                    >
                      <TinderCard
                        ref={childRefs[index]}
                        className='swipe'
                        key={character.productCode}
                        swipeThreshold={30}
                        preventSwipe={["up", "down"]}
                        onSwipe={(dir) => swiped(dir, index)}
                        swipeRequirementType={200}
                        // onSwipeRequirementFulfilled={(dir) => {
                        //   setSwipeDirection(dir);
                        // }}
                        // onSwipeRequirementUnfulfilled={() => {
                        //   setSwipeDirection(null);
                        // }}
                        onCardLeftScreen={(dir) =>
                          outOfFrame(
                            dir,
                            character.productCode,
                            index,
                            character.title,
                            character.duration,
                            character.description,
                            character.pricing,
                            character.destinations,
                            character.productUrl
                          )
                        }
                      >
                        <div
                          style={{
                            backgroundImage:
                              "url(" +
                              character.images[0]?.variants[12].url +
                              ")",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                          className='cardName'
                        >
                          <ul
                            onClick={() => {
                              setShowDetailProperty(true);
                            }}
                            id='activity-details-swipe'
                            className='cardBox flex p-2 px-2 detailBox'
                          >
                            <li>
                              <h3>{character.title}</h3>
                              {/* <p>{oneTripDetail?.tripLocation}</p> */}
                              <p>
                                {propertyData?.[currentIndex]?.pricing
                                  ? `£${Math.round(
                                      propertyData?.[currentIndex]?.pricing
                                        ?.summary?.fromPrice
                                    )}`
                                  : "£XXX"}
                              </p>
                            </li>
                            <li className='pressable'>
                              <div className='pressable yellowBackgroundInfo pointer'>
                                <span className='pressable'>i</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        {swipeDirection === "left" &&
                        currentIndex === index - 1 ? (
                          <div className='swipe-overlay_right_img blueBackground'>
                            <img
                              src={close}
                              alt='nope'
                            />
                          </div>
                        ) : null}
                        {swipeDirection === "right" &&
                        currentIndex === index - 1 ? (
                          <div className='swipe-overlay_left_img yellowBackground'>
                            <img
                              src={blackHeart}
                              alt='like'
                            />
                          </div>
                        ) : null}
                      </TinderCard>
                    </div>
                  ))}
              </div>
              <div className='justify-content-center d-flex gap-5 mt-1 pressable'>
                <div
                  onClick={() => swipe("left")}
                  className='blueBackground pointer'
                >
                  <img
                    className='like-dislike-btn'
                    src={disLike}
                    alt='dislike'
                  />
                </div>
                <div
                  onClick={() => swipe("right")}
                  className='yellowBackground pointer'
                >
                  <img
                    className='like-dislike-btn'
                    src={like}
                    alt='like'
                  />
                </div>
              </div>
              {showDetailProperty && (
                <PropertyDetails
                  oneTripDetail={oneTripDetail}
                  showDetailProperty={showDetailProperty}
                  setShowDetailProperty={setShowDetailProperty}
                  propertyData={propertyData[currentIndex]}
                  isSwipe={true}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PropertyCards;
