import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Container, Form, InputGroup, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { name } from "../../helper/yup.validation.schema";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserNameData,
  getUserName,
  updateUserNameData,
} from "../../middleware/user";
import { useNavigate, useParams } from "react-router-dom";
import LocalStorageService from "../../helper/localstorage-services";
import ROUTE_URLS from "../../config/routes";
import useTitle from "../../helper/useTitle";
import { resetNameDetails } from "../../slice/userSlice";
import { resetAllDestinationProduct } from "../../slice/tripSlice";

const UserName = () => {
  useTitle("Name");
  const { tripCode } = useParams();
  const nav = useNavigate();
  const dispatch = useDispatch();
  const {
    updateUserNameLoading,
    updateUserName,
    addUserNameLoading,
    addUserName,
    userNameLoading,
    userName,
  } = useSelector((state) => state.user);
  const { user_id } = LocalStorageService.getLoggedInUserDetails();

  useEffect(() => {
    if (user_id) {
      dispatch(getUserName(user_id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      (!updateUserNameLoading && updateUserName) ||
      (!addUserNameLoading && addUserName)
    ) {
      if (userName.invite && tripCode) {
        nav(`${ROUTE_URLS.ACTIVITY}/${tripCode}`);
      } else {
        nav(ROUTE_URLS.HOLIDAY_NAME);
      }
      dispatch(resetNameDetails());
    }
    // eslint-disable-next-line
  }, [updateUserName, addUserName]);

  useEffect(() => {
    if (userName !== null && user_id) {
      formik.setFieldValue("name", userName?.name);
    }
    // eslint-disable-next-line
  }, [userName]);

  const handleSubmit = (values) => {
    if (userName?.name) {
      dispatch(updateUserNameData(user_id, values));
    } else {
      dispatch(addUserNameData(values));
    }
    dispatch(resetAllDestinationProduct());
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      user_id: user_id,
    },
    validationSchema: yup.object({
      name: name,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <div className="wprcontainer">
        {userNameLoading ? (
          <Spinner
            style={{
              color: "#ffc533",
            }}
            animation="border"
            variant="warning"
          />
        ) : (
          <div className="emailContainer">
            <h6 className="golbalText pb-4 mb-0">What’s your name?</h6>
            <form
              className="inputTag"
              id="What’s your name?"
              onSubmit={formik.handleSubmit}
            >
              <InputGroup className="pb-4 mb-2 innerInput">
                <Form.Control
                  type="text"
                  name="name"
                  className="inputTag"
                  id="What’s your name?"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Your first name"
                  aria-describedby="passwordHelpBlock"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="invalid-feedback d-block">
                    {formik.errors.name}
                  </div>
                )}
              </InputGroup>

              <button
                type="submit"
                className="golbalbtn"
                disabled={
                  addUserNameLoading || updateUserNameLoading ? true : false
                }
              >
                {addUserNameLoading || updateUserNameLoading
                  ? "Loading..."
                  : "Continue"}
              </button>
            </form>
          </div>
        )}
      </div>
    </Container>
  );
};

export default UserName;
