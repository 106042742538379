import React, { useEffect } from "react";
import { Badge, Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "../helper/localstorage-services";
import { getTripDetails } from "../middleware/trip";
import ROUTE_URLS from "../config/routes";
import { Link, useNavigate } from "react-router-dom";
import forwardArrow from "../assets/images/forwardArrow.svg";
import useTitle from "../helper/useTitle";
import {
  removeTripDetails,
  resetAllDestinationProduct,
} from "../slice/tripSlice";
import { dateWithMonthFormat } from "../helper/dateFormat";

const Account = () => {
  useTitle("My account");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { tripDetailsLoading, tripDetails } = useSelector(
    (state) => state.trip
  );
  const { user_id } = LocalStorageService.getLoggedInUserDetails();

  useEffect(() => {
    dispatch(getTripDetails(user_id));
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="wprcontainer">
        <div className="accountDiv">
          <div className="myAccount flex">
            <h3>My account</h3>
          </div>
          <div className="accountMainDiv">
            {tripDetailsLoading ? (
              <Spinner
                style={{
                  color: "#ffc533",
                }}
                animation="border"
                variant="warning"
              />
            ) : (
              <div>
                <h4 className="mb-3">My trips</h4>
                {tripDetails && tripDetails.length > 0 ? (
                  <>
                    {tripDetails?.map((trips) => (
                      <div key={trips._id} className="accountContainer">
                        <div
                          id="my-account-clicks-on-trips"
                          onClick={() => {
                            dispatch(resetAllDestinationProduct());
                            nav(
                              `${ROUTE_URLS.ACTIVITY}/${trips.tripName.replace(
                                /\s+/g,
                                "-"
                              )}_${trips?.tripCode}`
                            );
                          }}
                          className="flex accountWpr detailBox"
                        >
                          <div className="acccount_text">
                            <p style={{ fontSize: "14px", fontWeight: "700" }}>
                              {trips.tripName}
                            </p>
                            <p>
                              {dateWithMonthFormat(trips.checkIn)} -{" "}
                              {dateWithMonthFormat(trips.checkOut)}
                            </p>
                          </div>
                          <div className="ImgArrow">
                            <img
                              src={forwardArrow}
                              alt="arrow"
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </div>
                        {trips?.members && trips?.members?.length > 0 && (
                          <ul className="flex">
                            <Badge className="badge-secondary">
                              {trips.members[0].name?.slice(0, 2)}
                            </Badge>
                            {trips?.members?.length > 1 && (
                              <Badge className="badge-secondary">
                                {trips.members[1]?.name?.slice(0, 2)}
                              </Badge>
                            )}
                            {trips?.members?.length > 2 && (
                              <Badge className="badge-secondary">
                                {trips.members.length === 3
                                  ? trips.members[2].name?.slice(0, 2)
                                  : `+${trips.members.length - 2}`}
                              </Badge>
                            )}
                          </ul>
                        )}
                      </div>
                    ))}
                  </>
                ) : (
                  <p>No Trips Planned</p>
                )}
                <button
                  type="btn"
                  onClick={() => {
                    nav(ROUTE_URLS.HOLIDAY_NAME);
                    dispatch(removeTripDetails());
                  }}
                  style={{ top: "290px" }}
                  className="golbalbtn"
                >
                  Plan something new
                </button>
              </div>
            )}
            <div className="feedbackBottomBtn">
              <Link to="https://tally.so/r/3N7EeG" target="_blank">
                <button
                  type="btn"
                  style={{ background: "#E3F6F5" }}
                  className="golbalbtn"
                >
                  Send feedback
                </button>
              </Link>
              <button
                onClick={() => {
                  LocalStorageService.logoutUser();
                  nav(ROUTE_URLS.LOGIN);
                }}
                className="loginBtn"
                type="button"
              >
                Log out
              </button>
              <div className="extraSpace"></div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Account;
