import React, { useState } from "react";
import { Container, Modal, Toast } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import likedProfile from "../../assets/images/profile.svg";
import LocalStorageService from "../../helper/localstorage-services";
import copyImg from "../../assets/images/copyImg.svg";

const LikeModal = ({ show, setShow, likedBy, disLikedBy, tripCode }) => {
  const { name } = LocalStorageService.getLoggedInUserDetails();
  const [copy, setCopy] = useState(false);

  const copyFunction = () => {
    let copyText = document.getElementById("copyLink");
    navigator?.clipboard?.writeText(copyText.innerHTML);
    setCopy(true);
  };

  return (
    <Modal
      show={show}
      fullscreen={true}
      animation={false}
      onHide={() => setShow(false)}
    >
      <Modal.Body className="p-0">
        <Container>
          <div className="wprcontainer">
            <div className="accountDiv">
              <div>
                {copy && (
                  <Toast
                    onClose={() => setCopy(false)}
                    show={copy}
                    delay={2000}
                    autohide
                    bg={"success"}
                    className="toast-head"
                  >
                    <Toast.Body className="text-white">
                      Successfully Copied
                    </Toast.Body>
                  </Toast>
                )}
              </div>{" "}
              <div className="myAccount flex mt-3">
                <h6 className="golbalText mb-0">Who liked this?</h6>
                <div
                  onClick={() => {
                    setShow(false);
                  }}
                  style={{ right: "0", left: "auto" }}
                  className="position__Arrow blueBackground pointer"
                  id="close-like-user-details"
                >
                  <img src={close} alt="close" />
                </div>
              </div>
              <div className="pb-4">
                {likedBy && likedBy.length > 0 && (
                  <>
                    <h6 className="golbalText likesDetail mb-0">
                      {likedBy.length} {likedBy.length === 1 ? "like" : "likes"}
                    </h6>
                    <hr />
                    <ul className="pr-4">
                      {likedBy.map((o, i) => (
                        <li key={i} className="likedUser mb-2">
                          <div className="headerBgBlue">
                            <img src={likedProfile} alt="user" />
                          </div>
                          <p
                            style={{
                              marginBottom: "0",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {o.name}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
              <div>
                <h6 className="golbalText likesDetail mb-0">
                  {disLikedBy.length}{" "}
                  {disLikedBy.length <= 1 ? "dislike" : "dislikes"}
                </h6>
                <hr />
                {disLikedBy && disLikedBy.length > 0 && (
                  <>
                    <ul className="pr-4">
                      {disLikedBy.map((o) => (
                        <li className="likedUser mb-2">
                          <div className="headerBgBlue">
                            <img src={likedProfile} alt="user" />
                          </div>
                          <p
                            style={{
                              marginBottom: "0",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {o.name}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </div>

            {likedBy &&
              likedBy.length === 1 &&
              disLikedBy &&
              disLikedBy.length === 0 && (
                <div className="emailContainer" style={{ minHeight: "50%" }}>
                  <h6 className="golbalText pb-4">Invite your friends!</h6>
                  <p className="pb-3">
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        display: "block",
                      }}
                    >
                      Porridge is better shared with friends.
                    </span>
                    Use this link to invite others to help pick your something
                    fun to do. They’ll see the same activities you do, and your
                    combined votes will rank them.
                  </p>
                  <div className="invite-box">
                    <p id="copyLink">
                      https://getporridge.com/invite/
                      {name?.replace(/\s+/g, "-")}_{tripCode}
                    </p>
                    <div
                      onClick={() => copyFunction()}
                      className="copyBg pointer detailBox"
                      id="copy-invite-url"
                    >
                      <img src={copyImg} alt="copyImage" />
                    </div>
                  </div>
                </div>
              )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default LikeModal;
