import { get, post, put } from ".";

const URI = "/user";

const getTrip = (user_id) => {
  const URL = `${URI}/alldata/${user_id}`;
  return get(URL);
};

const addTrip = (payload) => {
  const URL = `${URI}/addTrip`;
  return post(URL, payload);
};

const getOneTripDetail = (tripCode) => {
  const URL = `${URI}/getTripbyCode/${tripCode}`;
  return get(URL);
};

const likeProperty = (tripCode, payload) => {
  const URL = `${URI}/likeDestination/${tripCode}`;
  return post(URL, payload);
};

const notification = (tripCode, payload) => {
  const URL = `${URI}/notification/${tripCode}`;
  return post(URL, payload);
};

const disLikeProperty = (tripCode, payload) => {
  const URL = `${URI}/disLiked/${tripCode}`;
  return post(URL, payload);
};

const allDestinations = (payload) => {
  const URL = `${URI}/getAllDestinations${payload ? `?search=${payload}` : ""}`;
  return get(URL);
};

const oneDestination = (productCode) => {
  const URL = `${URI}/getOneDestination/${productCode}`;
  return get(URL);
};

const alleDestinationProduct = (payload) => {
  const URL = `${URI}/productSearch`;
  return post(URL, payload);
};

const swpieCurrntPage = (tripCode, payload) => {
  const URL = `${URI}/lastCountForPagination/${tripCode}`;
  return post(URL, payload);
};

const getOneTripLocation = (tripCode) => {
  const URL = `${URI}/getTripLocation/${tripCode}`;
  return get(URL);
};

const updateTripLocation = (tripCode, payload) => {
  const URL = `${URI}/updateLocation/${tripCode}`;
  return put(URL, payload);
};

const TripService = {
  getTrip,
  addTrip,
  getOneTripDetail,
  likeProperty,
  notification,
  disLikeProperty,
  allDestinations,
  oneDestination,
  alleDestinationProduct,
  swpieCurrntPage,
  getOneTripLocation,
  updateTripLocation,
};

export default TripService;
