import React, { useState } from "react";
import { Container, Toast } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import copyImg from "../../assets/images/copyImg.svg";
import ROUTE_URLS from "../../config/routes";
import LocalStorageService from "../../helper/localstorage-services";
import { resetAllDestinationProduct } from "../../slice/tripSlice";
import { useDispatch } from "react-redux";

const InviteUser = () => {
  const { tripCode } = useParams();
  const [show, setShow] = useState(false);
  const nav = useNavigate();
  const { name } = LocalStorageService.getLoggedInUserDetails();
  const code = tripCode.split("_");
  const dispatch = useDispatch();

  const copyFunction = () => {
    let copyText = document.getElementById("copyLink");
    navigator?.clipboard?.writeText(copyText.innerHTML);
    setShow(true);
  };
  return (
    <Container>
      <div className="wprcontainer">
        <div style={{ height: "10px" }}>
          {show && (
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={3000}
              autohide
              bg={"success"}
            >
              <Toast.Body className="text-white">
                Successfully Copied
              </Toast.Body>
            </Toast>
          )}
        </div>
        <div className="emailContainer">
          <h6 className="golbalText pb-4">Invite your friends!</h6>
          <p className="pb-3">
            <span
              style={{
                fontSize: "14px",
                fontWeight: "700",
                display: "block",
              }}
            >
              Porridge is better shared with friends.
            </span>
            Use this link to invite others to help pick your something fun to
            do. They’ll see the same activities you do, and your combined votes
            will rank them.
          </p>
          <div className="invite-box">
            <p id="copyLink">
              https://getporridge.com/invite/{name.replace(/\s+/g, "-")}_
              {code[1]}
            </p>
            <div
              onClick={() => copyFunction()}
              className="copyBg pointer detailBox"
              id="copy-invite-url"
            >
              <img src={copyImg} alt="copyImage" />
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              nav(
                `${ROUTE_URLS.ACTIVITY}/${code[0].replace(/\s+/g, "-")}_${
                  code[1]
                }`
              );
              dispatch(resetAllDestinationProduct());
            }}
            className="loginBtn mt-2"
          >
            Skip for now
          </button>
        </div>
      </div>
    </Container>
  );
};

export default InviteUser;
