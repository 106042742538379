import { createSlice, current } from "@reduxjs/toolkit";
import { TRIP_SLICE } from "../config/constant";

export const tripSlice = createSlice({
  name: TRIP_SLICE,
  initialState: {
    //get trip states
    tripDetailsLoading: false,
    tripDetails: [],
    tripDetailsError: "",

    //add trip states
    addTripLoading: false,
    addTripDetails: null,
    addTripError: "",

    //get single trip details by id
    oneTripDetailLoading: true,
    oneTripDetail: [],
    oneTripDetailError: "",

    //like property
    likePropertyLoading: false,
    likeProperty: null,
    likePropertyError: "",

    //disLike property
    disLikePropertyLoading: false,
    disLikeProperty: null,
    disLikePropertyError: "",

    //notification for like property
    notificationLoading: false,
    notification: null,
    notificationError: "",

    //trip details states
    tripName: "",
    tripDate: null,
    tripLocation: null,

    //get all destinations details
    destinationLoading: true,
    destinationDetails: [],
    destinationError: "",

    //get one product Information
    oneProductLoading: true,
    oneProduct: null,
    oneProductError: "",

    //all destination details with pagination
    allDestinationProductLoading: true,
    allDestinationProduct: [],
    allDestinationProductError: "",
    allDestinationProductPagination: null,

    swpieCurrntPageLoading: false,
    swpieCurrntPage: null,
    swpieCurrntPageError: "",

    //get selected trip location detail
    selectedTripLocationLoading: false,
    selectedTripLocation: null,
    selectedTripLocationError: "",

    //update selected trip location detail
    updateTripLocationLoading: false,
    updateTripLocationMessage: null,
    updateTripLocationError: "",
  },
  reducers: {
    //trip details
    addTripName: (state, action) => {
      state.tripName = action.payload;
    },
    addTripDate: (state, action) => {
      state.tripDate = action.payload;
    },
    addTripLocation: (state, action) => {
      state.tripLocation = action.payload;
    },
    //trip details

    //addTrip
    addTripRequest: (state) => {
      state.addTripLoading = true;
      state.addTripError = "";
    },
    addTripSuccess: (state, action) => {
      state.addTripDetails = action.payload;
      state.addTripLoading = false;
    },
    addTripError: (state, action) => {
      state.addTripLoading = false;
      state.addTripError = action.payload;
    },
    //addTrip

    //oneTripDetails
    oneTripDetailRequest: (state) => {
      state.oneTripDetailLoading = true;
      state.oneTripDetailError = "";
    },
    oneTripDetailSuccess: (state, action) => {
      state.oneTripDetailLoading = false;
      const prevPropertyData = current(state.oneTripDetail);
      if (
        !Array.isArray(prevPropertyData) &&
        action.payload.likedDestination.length !==
        prevPropertyData.likedDestination.length
      ) {
        const newLikedDestination = action.payload.likedDestination;
        const notificationUpdate = action.payload.members;
        state.oneTripDetail = {
          ...prevPropertyData,
          likedDestination: newLikedDestination,
          members: notificationUpdate,
        };
      } else {
        state.oneTripDetail = action.payload;
      }
    },
    oneTripDetailError: (state, action) => {
      state.oneTripDetailLoading = false;
      state.oneTripDetailError = action.payload;
    },
    resetOneTripDetail: (state) => {
      state.oneTripDetail = [];
      state.oneTripDetailError = "";
    },
    //oneTripDetail

    //tripDetails
    tripDetailsRequest: (state) => {
      state.tripDetailsLoading = true;
      state.tripDetailsError = "";
    },
    tripDetailsSuccess: (state, action) => {
      state.tripDetailsLoading = false;
      state.tripDetails = action.payload;
    },
    tripDetailsError: (state, action) => {
      state.tripDetailsLoading = false;
      state.tripDetailsError = action.payload;
    },
    //tripDetails

    //like property
    likePropertyRequest: (state) => {
      state.likePropertyLoading = true;
      state.likePropertyError = "";
    },
    likePropertySuccess: (state, action) => {
      state.likePropertyLoading = false;
      state.likeProperty = action.payload;
    },
    likePropertyError: (state, action) => {
      state.likePropertyLoading = false;
      state.likePropertyError = action.payload;
    },
    //like property

    //like property
    disLikePropertyRequest: (state) => {
      state.disLikePropertyLoading = true;
      state.disLikePropertyError = "";
    },
    disLikePropertySuccess: (state, action) => {
      state.disLikePropertyLoading = false;
      state.disLikeProperty = action.payload;
    },
    disLikePropertyError: (state, action) => {
      state.disLikePropertyLoading = false;
      state.disLikePropertyError = action.payload;
    },
    //like property

    //notification for like property
    notificationRequest: (state) => {
      state.notificationLoading = true;
      state.notificationError = "";
    },
    notificationSuccess: (state, action) => {
      state.notificationLoading = false;
      state.notification = action.payload;
    },
    notificationError: (state, action) => {
      state.notificationError = action.payload;
      state.notificationLoading = false;
    },
    notificationReset: (state) => {
      state.notificationError = "";
      state.notification = "";
    },
    //notification for like property

    //destination details for trip name search
    destinationRequest: (state) => {
      state.destinationLoading = true;
      state.destinationDetails = [];
      state.destinationError = "";
    },
    destinationSuccess: (state, action) => {
      state.destinationLoading = false;
      state.destinationDetails = action.payload;
    },
    destinationError: (state, action) => {
      state.destinationError = action.payload;
      state.destinationLoading = false;
    },
    //destination details for trip name search

    //get one product details
    oneProductRequest: (state) => {
      state.oneProductLoading = true;
      state.oneProductError = "";
    },
    oneProductSuccess: (state, action) => {
      state.oneProductLoading = false;
      state.oneProduct = action.payload;
    },
    oneProductError: (state, action) => {
      state.oneProductError = action.payload;
      state.oneProductLoading = false;
    },

    resetOneProduct: (state) => {
      state.oneProductError = "";
      state.oneProduct = null;
    },
    //get one product details

    //remove trip details
    removeTripDetails: (state) => {
      state.tripName = "";
      state.tripDate = null;
      state.tripLocation = null;
      state.addTripDetails = null;
    },
    //remove trip details

    // get app destination product
    allDestinationProductRequest: (state) => {
      state.allDestinationProductLoading = true;
      state.allDestinationProductError = "";
    },
    allDestinationProductSuccess: (state, action) => {
      state.allDestinationProductLoading = false;
      state.allDestinationProduct = action.payload.data;
      state.allDestinationProductPagination = action.payload.pagination;
    },
    allDestinationProductError: (state, action) => {
      state.allDestinationProductError = action.payload;
      state.allDestinationProductLoading = false;
    },

    resetAllDestinationProduct: (state) => {
      state.allDestinationProduct = [];
      state.allDestinationProductPagination = null;
      // state.oneTripDetail = [];
    },
    // get app destination product

    //update swipe card pagination
    swpieCurrntPageRequest: (state) => {
      state.swpieCurrntPageLoading = true;
      state.swpieCurrntPageError = "";
    },

    swpieCurrntPageSuccess: (state, action) => {
      state.swpieCurrntPageLoading = false;
      state.swpieCurrntPage = action.payload;
    },
    swpieCurrntPageError: (state, action) => {
      state.swpieCurrntPageLoading = false;
      state.swpieCurrntPageError = action.payload;
    },
    //update swipe card pagination

    //selected trip location
    selectedTripLocationRequest: (state) => {
      state.selectedTripLocationLoading = true;
      state.selectedTripLocationError = "";
    },
    selectedTripLocationSuccess: (state, action) => {
      state.selectedTripLocationLoading = false;
      state.selectedTripLocation = action.payload;
    },
    selectedTripLocationError: (state, action) => {
      state.selectedTripLocationLoading = false;
      state.selectedTripLocationError = action.payload;
    },
    resetSelectedLocationMessage: (state) => {
      state.selectedTripLocationError = "";
    },
    //selected trip location

    //update trip location
    updateTripLocationRequest: (state) => {
      state.updateTripLocationLoading = true;
      state.updateTripLocationError = "";
    },
    updateTripLocationSuccess: (state, action) => {
      state.updateTripLocationLoading = false;
      state.updateTripLocationMessage = action.payload;
    },
    updateTripLocationError: (state, action) => {
      state.updateTripLocationLoading = false;
      state.updateTripLocationError = action.payload;
    },
    resetUpdateMessage: (state) => {
      state.updateTripLocationMessage = "";
      state.updateTripLocationError = "";
    },
    //update trip location
  },
});

export const {
  tripDetailsRequest,
  tripDetailsSuccess,
  tripDetailsError,

  addTripRequest,
  addTripSuccess,
  addTripError,

  addTripName,
  addTripDate,
  addTripLocation,
  addTripMembers,
  removeTripDetails,

  oneTripDetailRequest,
  oneTripDetailSuccess,
  oneTripDetailError,
  resetOneTripDetail,

  likePropertyRequest,
  likePropertySuccess,
  likePropertyError,

  disLikePropertyRequest,
  disLikePropertySuccess,
  disLikePropertyError,

  notificationRequest,
  notificationSuccess,
  notificationError,
  notificationReset,

  destinationRequest,
  destinationSuccess,
  destinationError,

  oneProductRequest,
  oneProductSuccess,
  oneProductError,
  resetOneProduct,

  allDestinationProductRequest,
  allDestinationProductSuccess,
  allDestinationProductError,
  resetAllDestinationProduct,

  swpieCurrntPageRequest,
  swpieCurrntPageSuccess,
  swpieCurrntPageError,

  selectedTripLocationSuccess,
  selectedTripLocationRequest,
  selectedTripLocationError,
  resetSelectedLocationMessage,

  updateTripLocationRequest,
  updateTripLocationError,
  updateTripLocationSuccess,
  resetUpdateMessage,
} = tripSlice.actions;

export default tripSlice.reducer;
