import * as yup from "yup";

export const email = yup
  .string("Enter your email")
  .email("Invalid Email Address")
  .required("Email is required");

export const name = yup
  .string("Please only use letters")
  .required("Field is required")
  .min(2, "Please enter a valid name");

export const dateValidation = yup.object().shape({
  checkIn: yup.string("Enter Date").required("Date is required"),
  checkOut: yup.string("Enter Date").required("Date is required"),
});
