import React from "react";
import { Container, Form, InputGroup } from "react-bootstrap";
import BackArrow from "../../components/BackArrow";
import ROUTE_URLS from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { name } from "../../helper/yup.validation.schema";
import { addTripName } from "../../slice/tripSlice";
import useTitle from "../../helper/useTitle";
import LocalStorageService from "../../helper/localstorage-services";

const TripName = () => {
  useTitle("Trip");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { tripName } = useSelector((state) => state.trip);
  const { verified } = LocalStorageService.getLoggedInUserDetails();

  const handleSubmit = (values) => {
    dispatch(addTripName(values));
    nav(ROUTE_URLS.HOLIDAY_DATES);
  };

  const formik = useFormik({
    initialValues: { trip_name: tripName ? tripName.trip_name : "" },
    validationSchema: yup.object().shape({
      trip_name: name,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <div className="wprcontainer">
        <div className="position__Arrow">
          <BackArrow
            route={verified ? ROUTE_URLS.MY_ACCOUNT : ROUTE_URLS.USER_NAME}
            id="back-button-Give-your-trip-a-name!"
          />
        </div>
        <div className="emailContainer">
          <h6 className="golbalText pb-4">Give your trip a name!</h6>
          <form
            className="inputTag"
            id="Give your trip a name!"
            onSubmit={formik.handleSubmit}
          >
            <InputGroup className="pb-4 mb-2 innerInput">
              <Form.Control
                placeholder="Weekend getaway"
                name="trip_name"
                type="text"
                className="inputTag"
                id="Give your trip a name!"
                value={formik.values.trip_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.trip_name && formik.errors.trip_name && (
                <div className="invalid-feedback d-block">
                  {formik.errors.trip_name}
                </div>
              )}
            </InputGroup>
            <button type="submit" className="golbalbtn">
              Continue
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default TripName;
