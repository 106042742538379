import {
  HOLIDAY_DATES,
  HOLIDAY_GROUP,
  HOLIDAY_LOCATION,
  HOLIDAY_NAME,
  INVITED_USER,
  INVITE_USER,
  LOGIN,
  MY_ACCOUNT,
  ACTIVITY,
  SEND_MAIL,
  SIGNUP,
  USER_CHECK,
  USER_NAME,
} from "./constant";

const BASE_URL = "/";
const ROUTE_URLS = {
  HOME: BASE_URL,
  LOGIN: `${BASE_URL}${LOGIN}`,
  SIGNUP: `${BASE_URL}${SIGNUP}`,
  SEND_MAIL: `${BASE_URL}${SEND_MAIL}`,
  USER_CHECK: `${BASE_URL}${USER_CHECK}`,
  USER_NAME: `${BASE_URL}${USER_NAME}`,
  HOLIDAY_NAME: `${BASE_URL}${HOLIDAY_NAME}`,
  HOLIDAY_DATES: `${BASE_URL}${HOLIDAY_DATES}`,
  HOLIDAY_GROUP: `${BASE_URL}${HOLIDAY_GROUP}`,
  HOLIDAY_LOCATION: `${BASE_URL}${HOLIDAY_LOCATION}`,
  MY_ACCOUNT: `${BASE_URL}${MY_ACCOUNT}`,
  ACTIVITY: `${BASE_URL}${ACTIVITY}`,
  INVITE_USER: `${BASE_URL}${INVITE_USER}`,
  INVITED_USER: `${BASE_URL}${INVITED_USER}`,
};

export default ROUTE_URLS;
