import React, { useEffect } from "react";
import { Alert, Container, Form, InputGroup } from "react-bootstrap";
import BackArrow from "../../components/BackArrow";
import { useFormik } from "formik";
import ROUTE_URLS from "../../config/routes";
import * as yup from "yup";
import { email } from "../../helper/yup.validation.schema";
import { useDispatch, useSelector } from "react-redux";
import { signupUser } from "../../middleware/auth";
import { useNavigate } from "react-router-dom";
import useTitle from "../../helper/useTitle";

const Signup = () => {
  useTitle("signup");
  const { registerUserError, registerUser, registerUserLoading } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (registerUser && !registerUserError) {
      nav(ROUTE_URLS.SEND_MAIL);
    }
    // eslint-disable-next-line
  }, [registerUserError, registerUser]);

  const handleSubmit = (values) => {
    dispatch(signupUser(values));
  };

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object().shape({
      email: email,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <div className="wprcontainer">
        <div className="position__Arrow">
          <BackArrow
            route={ROUTE_URLS.HOME}
            id="back-button-register-user-page"
          />
        </div>

        <div className="emailContainer">
          <h6 className="golbalText pb-4">
            <span>Let’s get started. </span>
            <br /> What’s your email?
          </h6>

          <div>
            {registerUserError && (
              <Alert variant="danger">
                {registerUserError?.message
                  ? registerUserError?.message
                  : "Something went wrong! Please try again later."}
              </Alert>
            )}
          </div>

          <form
            className="inputTag"
            id="register-user-input"
            onSubmit={formik.handleSubmit}
          >
            <InputGroup className="pb-4 mb-2 innerInput">
              <Form.Control
                type="email"
                name="email"
                className="inputTag"
                id="register-user-input"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Your Email Address"
                aria-describedby="passwordHelpBlock"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback d-block">
                  {formik.errors.email}
                </div>
              )}
            </InputGroup>

            <button
              type="submit"
              className="golbalbtn"
              disabled={registerUserLoading ? true : false}
            >
              {registerUserLoading ? "Loading..." : "Continue"}
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Signup;
