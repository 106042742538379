import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slice/authSlice";
import userSlice from "../slice/userSlice";
import tripSlice from "../slice/tripSlice";
import { ENV_DEVELOPMENT, ENV_PRODUCTION } from "./constant";

function configStore() {
  const currentEnv = process.env.REACT_APP_ENV || ENV_DEVELOPMENT;
  const store = configureStore({
    reducer: {
      auth: authSlice,
      user: userSlice,
      trip: tripSlice,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    devTools: currentEnv !== ENV_PRODUCTION,
  });
  return store;
}

export default configStore;
