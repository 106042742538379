import { get, post, put } from ".";

const URI = "/user";

const getUserById = (user_id) => {
  const URL = `${URI}/getUser/${user_id}`;
  return get(URL);
};
const getName = (user_id) => {
  const URL = `${URI}/getName/${user_id}`;
  return get(URL);
};
const updateName = (user_id, payload) => {
  const URL = `${URI}/updateName/${user_id}`;
  return put(URL, payload);
};
const addName = (payload) => {
  const URL = `${URI}/addName`;
  return post(URL, payload);
};
const UserService = {
  getUserById,
  getName,
  addName,
  updateName,
};

export default UserService;
