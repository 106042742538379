import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Alert, Container, Form, InputGroup } from "react-bootstrap";
import * as yup from "yup";
import { email } from "../../helper/yup.validation.schema";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";
import { addInvitedUser } from "../../middleware/auth";
import vectorLine from "../../assets/images/vectorLine.svg";
import HomePageContent from "../../components/HomePageContent";

const LoginInvitedUser = () => {
  const dispatch = useDispatch();
  const { invitedUserLoading, invitedUser, invitedUserError } = useSelector(
    (state) => state.auth
  );
  const nav = useNavigate();
  const { tripCode } = useParams();
  const trip_code = tripCode.split("_");

  useEffect(() => {
    if (invitedUser && !invitedUserLoading) {
      nav(ROUTE_URLS.SEND_MAIL);
    }
    // eslint-disable-next-line
  }, [invitedUser]);

  const handleSubmit = (values) => {
    dispatch(addInvitedUser(values));
  };

  const formik = useFormik({
    initialValues: { email: "", tripCode: trip_code[1] },
    validationSchema: yup.object().shape({
      email: email,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <Container>
      <div className="wprcontainer">
        <div className="userWpr">
          <div className="userInner">
            <div className="logo">
              <h1 className="p-0">Porridge</h1>
            </div>

            <h6 className="golbalText pb-4">
              Plan and book <br /> your holiday activities <br /> with friends{" "}
              <br />
              <img src={vectorLine} alt="line" />
            </h6>

            <div className="emailContainer">
              {invitedUserError && (
                <Alert variant="danger">
                  {invitedUserError?.message
                    ? invitedUserError?.message
                    : "Something went wrong! Please try again later."}
                </Alert>
              )}
              <h6 className="golbalText pb-2">
                You’re invited! <br />
                <span>{trip_code[0]} has invited you to join their trip.</span>
              </h6>
              <div></div>
              <form
                className="inputTag"
                id="invite-input"
                onSubmit={formik.handleSubmit}
              >
                <InputGroup className="pb-2 mb-2 innerInput">
                  <Form.Control
                    type="email"
                    className="inputTag"
                    id="invite-input"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Your Email Address"
                    aria-describedby="passwordHelpBlock"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback d-block">
                      {formik.errors.email}
                    </div>
                  )}
                </InputGroup>

                <button
                  type="submit"
                  className="golbalbtn"
                  disabled={invitedUserLoading ? true : false}
                >
                  {invitedUserLoading ? "Loading..." : "Join in!"}
                </button>
              </form>
            </div>
            <HomePageContent />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoginInvitedUser;
