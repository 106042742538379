export function dateFormat(dateString, deliminator = "-") {
  try {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();
    return `${year}${deliminator}${
      month > 8 ? month + 1 : "0" + (month + 1)
    }${deliminator}${day > 9 ? day : "0" + day}`;
  } catch (error) {
    return `-----`;
  }
}

export function dateWithMonthFormat(dateString) {
  try {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear();
    return `${day > 9 ? day : 0 + day} ${month} ${year} ${" "}
        `;
  } catch (error) {
    return "-----";
  }
}
