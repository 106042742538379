import React, { useEffect } from "react";
import { Alert, Container, Form, InputGroup } from "react-bootstrap";
import BackArrow from "../../components/BackArrow";
import ROUTE_URLS from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { email } from "../../helper/yup.validation.schema";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../middleware/auth";
import useTitle from "../../helper/useTitle";

const Login = () => {
  useTitle("login");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { loginLoading, loginError, login } = useSelector(
    (state) => state.auth
  );

  const handleSubmit = (values) => {
    dispatch(loginUser(values));
  };

  useEffect(() => {
    if (login && !loginLoading) {
      nav(ROUTE_URLS.SEND_MAIL);
    }
    // eslint-disable-next-line
  }, [login]);

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: yup.object().shape({
      email: email,
    }),
    onSubmit: handleSubmit,
  });
  return (
    <Container>
      <div className="wprcontainer">
        <div className="position__Arrow">
          <BackArrow route={ROUTE_URLS.HOME} id="back-button-login-page" />
        </div>

        <div className="emailContainer">
          <h6 className="golbalText pb-4">
            Welcome back! <br />
            <span>Log into your account </span>
          </h6>
          <div>
            {loginError && (
              <Alert variant="danger">
                {loginError?.message
                  ? loginError?.message
                  : "Something went wrong! Please try again later."}
              </Alert>
            )}
          </div>
          <form
            className="inputTag"
            id="login-input"
            onSubmit={formik.handleSubmit}
          >
            <InputGroup className="pb-4 mb-2 innerInput">
              <Form.Control
                type="email"
                name="email"
                className="inputTag"
                id="login-input"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Your Email Address"
                aria-describedby="passwordHelpBlock"
              />
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback d-block">
                  {formik.errors.email}
                </div>
              )}
            </InputGroup>

            <button
              type="submit"
              className="golbalbtn mb-3"
              disabled={loginLoading ? true : false}
            >
              {loginLoading ? "Loading..." : "Log in"}
            </button>
          </form>
          <button
            onClick={() => {
              nav(ROUTE_URLS.SIGNUP);
            }}
            className="loginBtn"
            type="button"
          >
            Create Account
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Login;
