import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ROUTE_URLS from "../../config/routes";
import { useNavigate, useParams } from "react-router-dom";
import exportImg from "../../assets/images/export.svg";
import profile from "../../assets/images/profile.svg";
import { useDispatch, useSelector } from "react-redux";
import { getOneTripDetail } from "../../middleware/trip";
import ReactSwitch from "react-switch";
import rightSwitchOff from "../../assets/images/rightSwitchOff.svg";
import copySwitch from "../../assets/images/copySwitch.svg";
import leftSwipeOff from "../../assets/images/leftSwipeOff.svg";
import leftSwitch from "../../assets/images/leftSwitch.svg";
import rightSwitch from "../../assets/images/rightSwitch.svg";
import LikeProperties from "./LikeProperties";
import PropertyCards from "./PropertyCards";
import InviteFriends from "../../components/modals/InviteFriends";
import useTitle from "../../helper/useTitle";
import LocalStorageService from "../../helper/localstorage-services";
import { notificationReset, resetOneTripDetail } from "../../slice/tripSlice";

const Property = () => {
  useTitle("Property");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { tripCode } = useParams();
  const [switchValue, setSwitchValue] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const {
    oneTripDetail,
    likeProperty,
    oneTripDetailLoading,
    disLikeProperty,
    notification,
    swpieCurrntPage,
  } = useSelector((state) => state.trip);
  const [initialLoading, setInitialLoading] = useState(true);
  const { user_id } = LocalStorageService.getLoggedInUserDetails();

  useEffect(() => {
    if (tripCode) {
      const code = tripCode.split("_");
      dispatch(getOneTripDetail(code[1]));
    }
    // eslint-disable-next-line
  }, [tripCode, likeProperty, disLikeProperty, notification, swpieCurrntPage]);

  useEffect(() => {
    if (!oneTripDetailLoading && initialLoading) {
      setInitialLoading(false);
    }
    dispatch(notificationReset());
    // eslint-disable-next-line
  }, [oneTripDetail]);

  const userInfo =
    oneTripDetail?.members &&
    oneTripDetail?.members.length > 0 &&
    oneTripDetail.members.find((o) => {
      return o.user_id === user_id;
    });

  const centerLocation = {
    lat: oneTripDetail?.latitude,
    lng: oneTripDetail?.longitude,
  };

  return (
    <Container>
      <div className="wprcontainer">
        <ul className="closeImg mb-1">
          <li>
            <div
              onClick={() => {
                nav(ROUTE_URLS.MY_ACCOUNT);
                dispatch(resetOneTripDetail());
              }}
              className="header-Bg pointer"
            >
              <img src={profile} alt="profile" />
            </div>
          </li>
          <li>
            <div
              onClick={() => {
                setShowInvite(true);
              }}
              className="header-Bg pointer"
            >
              <img src={exportImg} alt="profile" />
            </div>
          </li>
        </ul>
        {oneTripDetailLoading && initialLoading ? (
          <>
            <Spinner
              style={{
                color: "#ffc533",
              }}
              animation="border"
              variant="warning"
            />
          </>
        ) : (
          <>
            {oneTripDetail ? (
              <>
                <div className="toggleBtn mb-4">
                  <ReactSwitch
                    onChange={() => {
                      setSwitchValue(!switchValue);
                      // localStorage.setItem(
                      //   "switchValue",
                      //   JSON.stringify(!switchValue)
                      // );
                    }}
                    checked={switchValue}
                    handleDiameter={28}
                    offColor="#E3F6F5"
                    onColor="#E3F6F5"
                    offHandleColor="#E3F6F5"
                    onHandleColor="#E3F6F5"
                    height={36}
                    width={75}
                    borderRadius={100}
                    activeBoxShadow="0px 0px 1px 2px #fffc35"
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "orange",
                          paddingRight: 2,
                        }}
                      >
                        {userInfo?.notification ? (
                          <img src={rightSwitch} alt="off" />
                        ) : (
                          <img src={rightSwitchOff} alt="no notification" />
                        )}
                      </div>
                    }
                    checkedIcon={<img src={copySwitch} alt="off" />}
                    uncheckedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 20,
                        }}
                      >
                        <img src={leftSwitch} alt="off" />
                      </div>
                    }
                    checkedHandleIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          color: "red",
                          fontSize: 18,
                        }}
                      >
                        <img src={leftSwipeOff} alt="on" />
                      </div>
                    }
                    className="react-switch"
                    id="small-radius-switch"
                  />
                </div>
                {switchValue ? (
                  <>
                    {centerLocation ? (
                      <LikeProperties
                        user_id={user_id}
                        tripCode={tripCode}
                        centerLocation={centerLocation}
                      />
                    ) : (
                      <h6 className="golbalText noProperty">
                        Your group hasn’t liked any properties yet.
                      </h6>
                    )}
                  </>
                ) : (
                  <>
                    {!Array.isArray(oneTripDetail) && userInfo && (
                      <PropertyCards userInfo={userInfo} />
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="emailContainer">
                <h6 className="golbalText pb-2">No Properties found!</h6>
              </div>
            )}
          </>
        )}
        {showInvite && (
          <InviteFriends
            setShowInvite={setShowInvite}
            showInvite={setShowInvite}
            tripCode={oneTripDetail?.tripCode}
            tripName={oneTripDetail?.tripName}
          />
        )}
      </div>
    </Container>
  );
};

export default Property;
