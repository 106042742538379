import TripService from "../services/tripService";
import {
  addTripError,
  addTripRequest,
  addTripSuccess,
  allDestinationProductError,
  allDestinationProductRequest,
  allDestinationProductSuccess,
  destinationError,
  destinationRequest,
  destinationSuccess,
  disLikePropertyError,
  disLikePropertyRequest,
  disLikePropertySuccess,
  likePropertyError,
  likePropertyRequest,
  likePropertySuccess,
  notificationError,
  notificationRequest,
  notificationSuccess,
  oneProductError,
  oneProductRequest,
  oneProductSuccess,
  oneTripDetailError,
  oneTripDetailRequest,
  oneTripDetailSuccess,
  selectedTripLocationError,
  selectedTripLocationRequest,
  selectedTripLocationSuccess,
  swpieCurrntPageError,
  swpieCurrntPageRequest,
  swpieCurrntPageSuccess,
  tripDetailsError,
  tripDetailsRequest,
  tripDetailsSuccess,
  updateTripLocationError,
  updateTripLocationRequest,
  updateTripLocationSuccess,
} from "../slice/tripSlice";

export function getTripDetails(user_id) {
  return (dispatch) => {
    dispatch(tripDetailsRequest(user_id));
    TripService.getTrip(user_id)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(tripDetailsSuccess(data));
        } else {
          dispatch(tripDetailsError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(tripDetailsError(error));
      });
  };
}

export function addTrip(payload) {
  return (dispatch) => {
    dispatch(addTripRequest());
    TripService.addTrip(payload)
      .then((response) => {
        const { data, message, error, status } = response.data;
        if (status === 1) {
          dispatch(addTripSuccess({ ...data, message }));
        } else {
          dispatch(addTripError(error));
        }
      })
      .catch((error) => {
        dispatch(addTripError(error));
      });
  };
}

export function getOneTripDetail(tripCode) {
  return (dispatch) => {
    dispatch(oneTripDetailRequest());
    TripService.getOneTripDetail(tripCode)
      .then((response) => {
        const { data, message, error, status } = response.data;
        if (status === 1) {
          dispatch(oneTripDetailSuccess(data));
        } else {
          dispatch(oneTripDetailError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(oneTripDetailError(error));
      });
  };
}

export function addLikeProperty(tripCode, payload) {
  return (dispatch) => {
    dispatch(likePropertyRequest());
    TripService.likeProperty(tripCode, payload)
      .then((response) => {
        const { data, status, message, error } = response.data;
        if (status === 1) {
          dispatch(likePropertySuccess({ ...data, message }));
        } else {
          dispatch(likePropertyError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(likePropertyError(error));
      });
  };
}

export function disableNotification(tripCode, payload) {
  return (dispatch) => {
    dispatch(notificationRequest());
    TripService.notification(tripCode, payload)
      .then((response) => {
        const { status, message, error } = response.data;
        if (status === 1) {
          dispatch(notificationSuccess(message));
        } else {
          dispatch(notificationError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(notificationError(error));
      });
  };
}

export function addDisLikeProperty(tripCode, payload) {
  return (dispatch) => {
    dispatch(disLikePropertyRequest());
    TripService.disLikeProperty(tripCode, payload)
      .then((response) => {
        const { data, status, message, error } = response.data;
        if (status === 1) {
          dispatch(disLikePropertySuccess({ ...data, message }));
        } else {
          dispatch(disLikePropertyError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(disLikePropertyError(error));
      });
  };
}

export function getAllDestinationDetails(payload) {
  return (dispatch) => {
    dispatch(destinationRequest());
    TripService.allDestinations(payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(destinationSuccess(data));
        } else {
          dispatch(destinationError(error));
        }
      })
      .catch((error) => dispatch(destinationError(error)));
  };
}

export function getOneProductData(productCode) {
  return (dispatch) => {
    dispatch(oneProductRequest());
    TripService.oneDestination(productCode)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(oneProductSuccess(data));
        } else {
          dispatch(oneProductError(error));
        }
      })
      .catch((error) => dispatch(oneProductError(error)));
  };
}

export function getAllDestinationProduct(payload) {
  return (dispatch) => {
    dispatch(allDestinationProductRequest());
    TripService.alleDestinationProduct(payload)
      .then((response) => {
        const { data, status, error, pagination } = response.data;
        if (status === 1) {
          dispatch(allDestinationProductSuccess({ data, pagination }));
        } else {
          dispatch(allDestinationProductError(error));
        }
      })
      .catch((error) => dispatch(allDestinationProductError(error)));
  };
}

export function updateSwpieCurrntPage(tripCode, payload) {
  return (dispatch) => {
    dispatch(swpieCurrntPageRequest());
    TripService.swpieCurrntPage(tripCode, payload)
      .then((response) => {
        const { data, status, error } = response.data;
        if (status === 1) {
          dispatch(swpieCurrntPageSuccess(data));
        } else {
          dispatch(swpieCurrntPageError(error));
        }
      })
      .catch((error) => dispatch(swpieCurrntPageError(error)));
  };
}

export function getSelectedTripLocation(tripCode) {
  return (dispatch) => {
    dispatch(selectedTripLocationRequest());
    TripService.getOneTripLocation(tripCode)
      .then((respone) => {
        const { status, data, error } = respone.data;
        if (status === 1) {
          dispatch(selectedTripLocationSuccess(data));
        } else {
          dispatch(selectedTripLocationError(error));
        }
      })
      .catch((error) => {
        dispatch(selectedTripLocationError(error));
      });
  };
}

export function updateTripLocation(tripCode, payload) {
  return (dispatch) => {
    dispatch(updateTripLocationRequest());
    TripService.updateTripLocation(tripCode, payload)
      .then((respone) => {
        const { status, data, error } = respone.data;
        if (status === 1) {
          dispatch(updateTripLocationSuccess(data));
        } else {
          dispatch(updateTripLocationError(error));
        }
      })
      .catch((error) => {
        dispatch(updateTripLocationError(error));
      });
  };
}
