import React, { useEffect, useState } from "react";
import { Alert, Container } from "react-bootstrap";
import BackArrow from "../../components/BackArrow";
import ROUTE_URLS from "../../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../helper/dateFormat";
import useTitle from "../../helper/useTitle";
import { addTrip } from "../../middleware/trip";
import LocalStorageService from "../../helper/localstorage-services";
import { removeTripDetails } from "../../slice/tripSlice";

const TripMembers = () => {
  useTitle("Trip");
  const [counter, setCounter] = useState(0);
  const [countErr, setCountErr] = useState(null);
  const dispatch = useDispatch();
  const {
    tripName,
    tripDate,
    addTripLoading,
    addTripDetails,
    tripLocation,
    addTripError,
  } = useSelector((state) => state.trip);
  const nav = useNavigate();

  const { user_id } = LocalStorageService.getLoggedInUserDetails();

  useEffect(() => {
    if (!tripDate) {
      nav(ROUTE_URLS.HOLIDAY_NAME);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (addTripDetails) {
      nav(
        `${ROUTE_URLS.INVITE_USER}/${addTripDetails.tripName}_${addTripDetails.tripCode}`
      );
    }
    // eslint-disable-next-line
  }, [addTripDetails]);

  const handleContinue = () => {
    if (counter < 1) {
      setCountErr("At lease you have to add one member");
    } else {
      setCountErr(null);
      const payload = {
        tripName: tripName.trip_name,
        tripMembers: counter,
        checkIn: dateFormat(tripDate.checkIn),
        checkOut: dateFormat(tripDate.checkOut),
        tripLocation: tripLocation.destinationName,
        destinationId: tripLocation.destinationId,
        // durationMinutes: tripLocation.distance,
        longitude: tripLocation.longitude,
        latitude: tripLocation.latitude,
        user_id: user_id,
        currency: "GBP",
      };
      dispatch(addTrip(payload));
      dispatch(removeTripDetails());
    }
  };

  return (
    <Container>
      <div className="wprcontainer">
        <div className="position__Arrow">
          <BackArrow
            route={ROUTE_URLS.HOLIDAY_LOCATION}
            id="back-button-How-many-people-are-you-going?"
          />
        </div>
        <div className="emailContainer">
          {addTripError && (
            <Alert variant="danger">
              Something went wrong! Please try again later.
            </Alert>
          )}
          <h6 className="golbalText pb-4">How many people are you going?</h6>
          <div className="checkInBtn gap-4 pb-4 mb-2">
            <button
              type="button"
              onClick={() => {
                setCounter(counter - 1);
              }}
              disabled={counter === 0 ? true : false}
              className={`${counter === 0 ? "removeBtn" : "addBtn"}`}
            >
              -
            </button>
            <button type="button" style={{ color: "#000000" }}>
              {counter}
            </button>
            <button
              type="button"
              onClick={() => {
                setCounter(counter + 1);
              }}
              className="addBtn"
            >
              +
            </button>
          </div>
          {countErr !== null && (
            <div className="mb-3 invalid-feedback d-block">{countErr}</div>
          )}
          <button
            type="button"
            disabled={addTripLoading ? true : false}
            onClick={handleContinue}
            className="golbalbtn"
          >
            {addTripLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      </div>
    </Container>
  );
};

export default TripMembers;
