import React, { useCallback, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ROUTE_URLS from "../../config/routes";
import BackArrow from "../../components/BackArrow";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllDestinationDetails,
  getSelectedTripLocation,
  updateTripLocation,
} from "../../middleware/trip";
import { useNavigate, useParams } from "react-router-dom";
import {
  addTripLocation,
  resetSelectedLocationMessage,
  resetUpdateMessage,
} from "../../slice/tripSlice";
import ToastService from "../../helper/toast-services";

const TripLocation = () => {
  // const [selectedDistance, setSelectedDistance] = useState(30);
  const { tripCode } = useParams();
  const [destinationData, setDestinationData] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const {
    destinationLoading,
    destinationDetails,

    tripDate,
    tripLocation,

    selectedTripLocationLoading,
    selectedTripLocation,
    selectedTripLocationError,

    updateTripLocationLoading,
    updateTripLocationMessage,
    updateTripLocationError,
  } = useSelector((state) => state.trip);

  useEffect(() => {
    if (!tripCode && !tripDate) {
      nav(ROUTE_URLS.HOLIDAY_DATES);
    } else {
      dispatch(getAllDestinationDetails());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedTripLocationLoading && selectedTripLocationError) {
      ToastService.error(selectedTripLocationError);
      dispatch(resetSelectedLocationMessage());
      nav(ROUTE_URLS.MY_ACCOUNT);
    }

    if (!updateTripLocationError && updateTripLocationMessage) {
      ToastService.success(updateTripLocationMessage);
      dispatch(resetUpdateMessage());
      nav(`${ROUTE_URLS.ACTIVITY}/${tripCode}`);
    } else if (!updateTripLocationMessage && updateTripLocationError) {
      ToastService.error(updateTripLocationError);
      dispatch(resetUpdateMessage());
    }
    // eslint-disable-next-line
  }, [
    selectedTripLocationError,
    updateTripLocationMessage,
    updateTripLocationError,
  ]);

  useEffect(() => {
    if (tripCode) {
      const code = tripCode.split("_");
      dispatch(getSelectedTripLocation(code[1]));
    }
    // eslint-disable-next-line
  }, [tripCode]);

  useEffect(() => {
    let tripData;
    if (tripLocation && tripLocation !== null) {
      tripData = tripLocation;
    } else if (selectedTripLocation && selectedTripLocation !== null) {
      tripData = selectedTripLocation;
    }

    if (tripData) {
      setSelectedDestination({
        destinationName: tripData.destinationName,
        destinationId: tripData.destinationId,
        value: tripData.destinationId,
        label: `${tripData.destinationName} (${tripData.timeZone})`,
        longitude: tripData.longitude,
        latitude: tripData.latitude,
      });
    }
    // eslint-disable-next-line
  }, [tripLocation, selectedTripLocation]);

  useEffect(() => {
    if (!destinationLoading && destinationDetails.length > 0) {
      const blankArr = [];
      destinationDetails.map((des) => {
        const data = {
          destinationName: des.destinationName,
          destinationId: des.destinationId,
          value: des.destinationId,
          label: `${des.destinationName} (${des.timeZone})`,
          longitude: des.longitude,
          latitude: des.latitude,
        };
        blankArr.push(data);
        return data;
      });
      setDestinationData(blankArr);
    }
    // eslint-disable-next-line
  }, [destinationDetails]);

  const handleSubmit = () => {
    if (selectedDestination === null) {
      setIsError(true);
    } else {
      let payload = {
        destinationName: selectedDestination.destinationName,
        destinationId: selectedDestination.destinationId,
        longitude: selectedDestination.longitude,
        latitude: selectedDestination.latitude,
      };

      if (tripCode && selectedTripLocation && selectedTripLocation !== null) {
        if (selectedTripLocation.destinationId === payload.destinationId) {
          nav(`${ROUTE_URLS.ACTIVITY}/${tripCode}`);
        } else {
          const code = tripCode.split("_");
          dispatch(updateTripLocation(code[1], payload));
        }
      } else {
        payload = {
          ...payload,
          value: selectedDestination.destinationId,
          label: `${selectedDestination.destinationName} (${selectedDestination.timeZone})`,
        };
        nav(ROUTE_URLS.HOLIDAY_GROUP);
        dispatch(addTripLocation(payload));
      }
      window.dataLayer.push({
        event: "trip_location",
        eventProps: {
          location: payload.destinationName,
        },
      });
    }
  };

  // const handleSelectedDistance = (distance) => {
  //   setSelectedDistance(distance);
  // };

  const handleDestinationChange = (selectedOption) => {
    setSelectedDestination(selectedOption);
    setIsError(false);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((input) => dispatch(getAllDestinationDetails(input))),
    []
  );

  return (
    <Container>
      {selectedTripLocationLoading ? (
        <div>
          <Spinner
            style={{
              color: "#ffc533",
            }}
            animation='border'
            variant='warning'
          />
        </div>
      ) : (
        <div className='wprcontainer'>
          <div className='position__Arrow'>
            <BackArrow
              id='back-button-Where-would-you-like-to-go?'
              route={
                tripCode
                  ? `${ROUTE_URLS.ACTIVITY}/${tripCode}`
                  : ROUTE_URLS.HOLIDAY_DATES
              }
            />
          </div>
          <div className='emailContainer'>
            <h6 className='golbalText pb-4'>Where would you like to go?</h6>
            {/* <div className="checkInBtn distance-btn pb-4 mb-2">
              <button
                type="button"
                onClick={() => handleSelectedDistance(30)}
                className={`${
                  selectedDistance === 30 ? "addBtn" : "bg-primary"
                }`}
              >
                30
              </button>
              <hr className="hline" />
              <button
                type="button"
                onClick={() => handleSelectedDistance(60)}
                className={`${
                  selectedDistance === 60 ? "addBtn" : "bg-primary"
                }`}
              >
                60
              </button>
              <hr className="hline" />
              <button
                type="button"
                onClick={() => handleSelectedDistance(90)}
                className={`${
                  selectedDistance === 90 ? "addBtn" : "bg-primary"
                }`}
              >
                90
              </button>
            </div>
            <span style={{ marginBottom: "15px" }}>
              Within <strong>{selectedDistance} minutes of</strong>
            </span> */}
            <form
              className='inputTag'
              id='Where would you like to go?'
              onSubmit={handleSubmit}
            >
              <ReactSelect
                classNamePrefix='select'
                className='pb-4 w-100 inputTag'
                id='Where would you like to go?'
                placeholder='Pick somewhere!'
                value={selectedDestination}
                onChange={(selectedOption) => {
                  handleDestinationChange(selectedOption);
                }}
                onInputChange={(input) => {
                  if (input) {
                    setMenuIsOpen(true);
                    handleSearch(input);
                  } else {
                    setMenuIsOpen(false);
                  }
                }}
                menuIsOpen={menuIsOpen}
                isLoading={destinationLoading}
                isClearable={true}
                styles={{
                  option: (provided, state) => ({
                    ...provided,
                    color: "black",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    "&:hover": {
                      borderColor: "rgba(255, 197, 51, 0.582)",
                      boxShadow: "0 0 0 0.25rem #ffc53352",
                    },
                    border: state.isFocused
                      ? "2px solid rgba(255, 197, 51, 0.582)"
                      : "2px solid #1a1302",
                    boxShadow: state.isFocused
                      ? "0 0 0 0.25rem #ffc53352"
                      : "none",
                    borderRadius: "10px",
                    height: "48px",
                  }),
                  indicatorsContainer: (prevStyle, state) => ({
                    ...prevStyle,
                    display: "none",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#FFE688",
                    primary: "#FFC533",
                  },
                })}
                isSearchable={true}
                name='color'
                options={destinationData}
              />
              {isError && (
                <div className='mb-2 mt-0 invalid-feedback d-block'>
                  Please select any place!
                </div>
              )}
              <button
                type='submit'
                disabled={updateTripLocationLoading}
                className='golbalbtn'
              >
                {updateTripLocationLoading ? "Loading..." : "Continue"}
              </button>
            </form>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TripLocation;
