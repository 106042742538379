import React, { useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../middleware/user";
import ROUTE_URLS from "../config/routes";

const UserCheck = () => {
  const { user_id, tripCode } = useParams();
  const dispatch = useDispatch();
  const { oneUser, oneUserError } = useSelector((state) => state.user);
  const nav = useNavigate();

  useEffect(() => {
    if (user_id) {
      dispatch(getUser(user_id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (oneUser) {
      if (oneUser.newUser && oneUser.invite) {
        nav(`${ROUTE_URLS.USER_NAME}/${tripCode}`);
      } else if (oneUser.newUser && !oneUser.invite) {
        nav(ROUTE_URLS.USER_NAME);
      } else if (oneUser.exists && oneUser.invite) {
        nav(`${ROUTE_URLS.ACTIVITY}/${tripCode}`);
      } else if (oneUser.verified) {
        nav(ROUTE_URLS.MY_ACCOUNT);
      } else {
        nav(ROUTE_URLS.HOME);
      }
    } else if (oneUserError) {
      nav(ROUTE_URLS.HOME);
    }
    // eslint-disable-next-line
  }, [oneUser, oneUserError]);

  return (
    <Container>
      <div>
        <Spinner
          style={{
            color: "#ffc533",
          }}
          animation='border'
          variant='warning'
        />
      </div>
    </Container>
  );
};

export default UserCheck;
