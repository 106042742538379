import { createSlice } from "@reduxjs/toolkit";
import { USER_SLICE } from "../config/constant";

export const userSlice = createSlice({
  name: USER_SLICE,
  initialState: {
    //one user states
    oneUserLoading: false,
    oneUser: null,
    oneUserError: "",

    //name states
    userNameLoading: false,
    userName: null,
    userNameError: "",

    //add name states
    addUserNameLoading: false,
    addUserName: null,
    addUserNameError: "",

    //updateUname states
    updateUserNameLoading: false,
    updateUserName: null,
    updateUserNameError: "",
  },
  reducers: {
    //updateUserName
    updateUserNameRequest: (state) => {
      state.updateUserNameLoading = true;
      state.updateUserNameError = "";
    },
    updateUserNameSuccess: (state, action) => {
      state.updateUserName = action.payload;
      state.updateUserNameLoading = false;
    },
    updateUserNameError: (state, action) => {
      state.updateUserNameLoading = false;
      state.updateUserNameError = action.payload;
    },
    //updateUserName

    //addUserName
    addUserNameRequest: (state) => {
      state.addUserNameLoading = true;
      state.addUserNameError = "";
    },
    addUserNameSuccess: (state, action) => {
      state.addUserName = action.payload;
      state.addUserNameLoading = false;
    },
    addUserNameError: (state, action) => {
      state.addUserNameLoading = false;
      state.addUserNameError = action.payload;
    },
    //addUserName

    //userName user
    userNameRequest: (state) => {
      state.userNameLoading = true;
      state.userNameError = "";
    },
    userNameSuccess: (state, action) => {
      state.userName = action.payload;
      state.userNameLoading = false;
    },
    userNameError: (state, action) => {
      state.userNameLoading = false;
      state.userNameError = action.payload;
    },
    //userName user

    //oneUser user
    oneUserRequest: (state) => {
      state.oneUserLoading = true;
      state.oneUserError = "";
    },
    oneUserSuccess: (state, action) => {
      state.oneUser = action.payload;
      state.oneUserLoading = false;
    },
    oneUserError: (state, action) => {
      state.oneUserLoading = false;
      state.oneUserError = action.payload;
    },
    //oneUser user
    resetNameDetails: (state) => {
      state.addUserName = "";
      state.updateUserName = "";
    },
  },
});

export const {
  oneUserRequest,
  oneUserSuccess,
  oneUserError,
  userNameRequest,
  userNameSuccess,
  userNameError,
  addUserNameRequest,
  addUserNameSuccess,
  addUserNameError,
  updateUserNameRequest,
  updateUserNameSuccess,
  updateUserNameError,
  resetNameDetails,
} = userSlice.actions;

export default userSlice.reducer;
