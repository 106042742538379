import axios from "axios";
import LocalStorageService from "../helper/localstorage-services";
import ROUTE_URLS from "../config/routes";
import {
  GENERIC_ERROR_MESSAGE,
  SERVER_AUTH_ERROR_STATUS_CODE,
} from "../config/constant";

const API_URL = process.env.REACT_APP_BASE_URL || "";
const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use(
  (config) => {
    const token = LocalStorageService.getLoggedInUserToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === SERVER_AUTH_ERROR_STATUS_CODE) {
      window.location.replace(ROUTE_URLS.HOME);
      LocalStorageService.logoutUser();
    }
    return Promise.reject(GENERIC_ERROR_MESSAGE);
  }
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config });
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config });
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config });
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config });
}
