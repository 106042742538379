import React from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../assets/images/backArrow.svg";

const BackArrow = ({ route, id = "" }) => {
  const nav = useNavigate();
  return (
    <div className="backBtn">
      <button
        type="button"
        onClick={() => {
          nav(route);
        }}
      >
        <div className="blueBackground" id={id}>
          <img src={backArrow} height="32px" width="32px" alt="back" />
        </div>
      </button>
    </div>
  );
};

export default BackArrow;
