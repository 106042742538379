import React, { useEffect, useState } from "react";
import { Container, Modal, Spinner } from "react-bootstrap";
import close from "../../assets/images/close.svg";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  addDisLikeProperty,
  addLikeProperty,
  getOneProductData,
} from "../../middleware/trip";
import disLike from "../../assets/images/dislike.svg";
import like from "../../assets/images/like.svg";
import LocalStorageService from "../../helper/localstorage-services";

const PropertyDetails = ({
  showDetailProperty,
  setShowDetailProperty,
  propertyData,
  oneTripDetail,
  isSwipe = true,
}) => {
  const [index, setIndex] = useState(1);
  const dispatch = useDispatch();
  const { oneProductLoading, oneProduct } = useSelector((state) => state.trip);
  const { user_id, name } = LocalStorageService.getLoggedInUserDetails();

  useEffect(() => {
    if (propertyData) {
      dispatch(getOneProductData(propertyData?.productCode));
    }
    // eslint-disable-next-line
  }, []);

  const handleLike = () => {
    const payload = {
      productCode: propertyData.productCode,
      title: propertyData.title,
      duration: propertyData.duration,
      description: propertyData.description,
      pricing: propertyData.pricing,
      destinations: propertyData.destinations,
      productUrl: propertyData.productUrl,
      user_id: user_id,
      name: name,
    };
    dispatch(addLikeProperty(oneTripDetail.tripCode, payload));
  };

  const handleDisLike = () => {
    dispatch(
      addDisLikeProperty(oneTripDetail.tripCode, {
        user_id: user_id,
        name: name,
        productCode: propertyData.productCode,
      })
    );
  };

  return (
    <>
      <Modal
        show={showDetailProperty}
        fullscreen={true}
        onHide={() => setShowDetailProperty(false)}
        className="p-0"
      >
        <Modal.Body className="p-0">
          <Container style={{ maxWidth: "450px" }} className="p-0 w-100">
            {oneProductLoading ? (
              <div>
                <Spinner
                  style={{
                    color: "#ffc533",
                  }}
                  animation="border"
                  variant="warning"
                />
              </div>
            ) : (
              <div className="wprcontainer p-0">
                <div
                  style={{
                    right: "5px",
                    zIndex: "1",
                    top: "15px",
                    left: "auto",
                  }}
                  onClick={() => {
                    setShowDetailProperty(false);
                  }}
                  className="position__Arrow blueBackground pointer"
                  id="close-button-activity-details"
                >
                  <img src={close} alt="close" />
                </div>
                <div className="position-relative">
                  <Carousel
                    dynamicHeight={true}
                    emulateTouch={true}
                    infiniteLoop={true}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={true}
                    showThumbs={false}
                    onChange={(e) => {
                      setIndex(e + 1);
                    }}
                    className="carouselDiv"
                    statusFormatter={() => {
                      return [index, "/", oneProduct?.images.length];
                    }}
                  >
                    {oneProduct?.images &&
                      oneProduct?.images.length > 0 &&
                      oneProduct?.images.map((src, i) => {
                        return (
                          <img
                            key={src.variants[12].url}
                            alt={`${src.caption}`}
                            src={src.variants[12].url}
                          />
                        );
                      })}
                  </Carousel>
                  {!isSwipe && (
                    <div className="likeDiv">
                      <div
                        onClick={() => {
                          setShowDetailProperty(false);
                          handleDisLike();
                        }}
                        className="blueBackground pointer"
                      >
                        <img
                          className="like-dislike-btn"
                          src={disLike}
                          alt="dislike"
                        />
                      </div>
                      <div
                        onClick={() => {
                          setShowDetailProperty(false);
                          handleLike();
                        }}
                        className="yellowBackground pointer"
                      >
                        <img
                          className="like-dislike-btn"
                          src={like}
                          alt="like"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="accountDiv p-3">
                  <div className="propertyName mt-2">
                    <ul className="flex justify-content-between align-content-center">
                      <li style={{ width: "70%", lineBreak: "auto" }}>
                        <h3>{propertyData?.title}</h3>
                        <p>{oneTripDetail?.tripLocation}</p>
                      </li>
                      <li>
                        <h3>
                          {propertyData?.pricing
                            ? `£${propertyData?.pricing?.summary?.fromPrice}`
                            : "£XXX"}
                        </h3>
                      </li>
                      {/* <div className="flex gap-4 guestDiv">
                        <Badge className="badge-secondary">
                          {propertyData?.allowGuest} guests
                        </Badge>
                        <Badge className="badge-secondary">
                          {propertyData?.bedrooms} beds
                        </Badge>
                        {propertyData?.allowspets === 1 ? (
                          <Badge className="badge-secondary">
                            Dog friendly
                          </Badge>
                        ) : (
                          <Badge className="badge-secondary">no dogs</Badge>
                        )}
                      </div> */}
                    </ul>
                    <div className="description">
                      <h3>Summary</h3>
                      {propertyData?.description?.split(". ")?.map((o, i) => (
                        <p key={i}>{o}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PropertyDetails;
