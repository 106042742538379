import React from "react";
import rightBox from "../assets/images/rightBox.svg";
import leftBox from "../assets/images/leftBox.svg";

const HomePageContent = () => {
  return (
    <div className="contentContainer">
      <h6 className="firstHead">
        Create a trip. Add your friends.
        <br /> And find your perfect match from <br /> over 300k tours and
        experiences.
      </h6>

      <h6 className="golbalText">
        Porridge is... <br />
      </h6>

      <div className="textImgBox">
        <p className="overImage">
          “The{" "}
          <strong>
            perfect way to figure out what you want
            <br /> to do
          </strong>{" "}
          while you’re away.”
        </p>
        <img alt="" className="rightImg float-end" src={rightBox} />
      </div>

      <div className="textImgBox">
        <p className="overImage p-2">
          &nbsp;&nbsp;&nbsp;“Great for{" "}
          <strong>getting your friends involved.</strong>”
        </p>
        <img alt="" className="leftImg float-start" src={leftBox} />
      </div>

      <div className="textImgBox">
        <p className="overImage">
          “<strong>So easy to use. </strong> It took maybe 5 minutes to
          <br /> get a plan together.”
        </p>
        <img alt="" className="rightImg float-end" src={rightBox} />
      </div>

      <h6 className="golbalText mt-5">
        Pick from over 300k tours
        <br /> and experiences
      </h6>
    </div>
  );
};

export default HomePageContent;
