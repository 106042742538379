import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import BackArrow from "../../components/BackArrow";
import ROUTE_URLS from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { dateValidation } from "../../helper/yup.validation.schema";
import DatePicker from "react-datepicker";
import { addTripDate } from "../../slice/tripSlice";
import useTitle from "../../helper/useTitle";

const TripDate = () => {
  useTitle("Trip");
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { tripName, tripDate } = useSelector((state) => state.trip);

  useEffect(() => {
    if (!tripName) {
      nav(ROUTE_URLS.HOLIDAY_NAME);
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmit = (values) => {
    dispatch(addTripDate(values));
    nav(ROUTE_URLS.HOLIDAY_LOCATION);
  };

  const formik = useFormik({
    initialValues: {
      checkIn: tripDate ? tripDate.checkIn : "",
      checkOut: tripDate ? tripDate.checkOut : "",
    },
    validationSchema: dateValidation,
    onSubmit: handleSubmit,
  });

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const handleChange = ([newStartDate, newEndDate]) => {
    formik.setFieldValue("checkIn", newStartDate);
    formik.setFieldValue("checkOut", newEndDate);
  };

  return (
    <Container>
      <div className="wprcontainer">
        <div className="position__Arrow">
          <BackArrow
            route={ROUTE_URLS.HOLIDAY_NAME}
            id="back-button-When-would-you-like-to-go?"
          />
        </div>
        <div className="emailContainer">
          <h6 className="golbalText pb-4">When would you like to go?</h6>
          <form
            className="inputTag"
            style={{ width: "auto" }}
            id="When would you like to go?"
            onSubmit={formik.handleSubmit}
          >
            <div className="checkInBtn pb-4 mb-2">
              <DatePicker
                name="checkIn"
                dateFormat="dd/MM/yyyy"
                selected={formik.values.checkIn}
                onChange={handleChange}
                // selected={formik.values.checkIn}
                // onChange={(dateArr) => {
                //   handleChange();
                //   // formik.setFieldValue("checkIn", dateArr);
                // }}
                startDate={formik.values.checkIn}
                endDate={formik.values.checkOut}
                minDate={new Date()}
                selectsRange
                onBlur={formik.handleBlur}
                placeholderText="Check in"
                id="When would you like to go?"
                className="checkInDate inputTag"
              />
            </div>
            <button type="submit" className="golbalbtn">
              Continue
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default TripDate;
