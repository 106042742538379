import AuthService from "../services/authService";
import {
  invitedUserError,
  invitedUserRequest,
  invitedUserSuccess,
  loginError,
  loginRequest,
  loginSuccess,
  registerUserError,
  registerUserRequest,
  registerUserSuccess,
} from "../slice/authSlice";

export function loginUser(payload) {
  return (dispatch) => {
    dispatch(loginRequest());
    AuthService.login(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(loginSuccess({ ...data, message, status }));
        } else {
          dispatch(loginError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(loginError(error));
      });
  };
}

export function signupUser(payload) {
  return (dispatch) => {
    dispatch(registerUserRequest());
    AuthService.signup(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(registerUserSuccess({ ...data, message }));
        } else {
          dispatch(registerUserError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(registerUserError(error));
      });
  };
}

export function addInvitedUser(payload) {
  return (dispatch) => {
    dispatch(invitedUserRequest());
    AuthService.invitedUser(payload)
      .then((response) => {
        const { data, message, error, status } = response.data;
        if (status === 1) {
          dispatch(invitedUserSuccess({ ...data, message }));
        } else {
          dispatch(invitedUserError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(invitedUserError(error));
      });
  };
}
