import React from "react";
import vectorLine from "../assets/images/vectorLine.svg";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import ROUTE_URLS from "../config/routes";
import HomePageContent from "../components/HomePageContent";

const Home = () => {
  const nav = useNavigate();
  return (
    <Container>
      <div className="wprcontainer">
        <div className="userWpr">
          <div className="userInner">
            <div className="logo">
              <h1 className="p-0">Porridge</h1>
            </div>

            <h6 className="golbalText pb-4">
              Plan and book <br /> your holiday activities <br /> with friends{" "}
              <br />
              <img src={vectorLine} alt="line" />
            </h6>

            <div className="flex gap-3 justify-content-center">
              <button
                onClick={() => {
                  nav(ROUTE_URLS.SIGNUP);
                }}
                type="button"
                className="golbalbtn"
              >
                Get started!
              </button>
              <button
                onClick={() => {
                  nav(ROUTE_URLS.LOGIN);
                }}
                className="loginBtn"
                type="button"
              >
                Log in
              </button>
            </div>
            <HomePageContent />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
