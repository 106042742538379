import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ROUTE_URLS from "../../config/routes";

const NotFound = () => {
  const nav = useNavigate();
  return (
    <Container>
      <div className="wprcontainer">
        <div className="userWpr">
          <div className="userInner">
            <div className="logo">
              <h1 className="p-0">404</h1>
            </div>

            <h6 className="golbalText pb-4 pt-3">
              There’s no porridge here...
            </h6>
            <div className="flex justify-content-center">
              <button
                style={{ width: "300px" }}
                onClick={() => {
                  nav(ROUTE_URLS.MY_ACCOUNT);
                }}
                type="button"
                className="golbalbtn"
              >
                Go to my account
              </button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NotFound;
