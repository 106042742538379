import { Loader } from "@googlemaps/js-api-loader";
import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import like from "../../assets/images/like.svg";
import LikeModal from "../../components/modals/LikeModal";
import { useDispatch, useSelector } from "react-redux";
import { disableNotification } from "../../middleware/trip";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import PropertyDetails from "../../components/modals/PropertyDetails";

const LikeProperties = ({ centerLocation, user_id }) => {
  const { oneTripDetail } = useSelector((state) => state.trip);
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const [showDetailProperty, setShowDetailProperty] = useState(false);

  const handleClickOnLikeActivity = (index) => {
    setIndex(index);
    setShowDetailProperty(true);
  };

  useEffect(() => {
    if (user_id) {
      dispatch(
        disableNotification(oneTripDetail?.tripCode, { user_id: user_id })
      );
    }
    // eslint-disable-next-line
  }, []);

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    version: "weekly",
    libraries: ["places"],
    // ...additionalOptions,
  });

  loader.load().then(async (google) => {
    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    const infoWindow = new InfoWindow();

    if (document.getElementById("map") !== null) {
      let mapDiv = new Map(document.getElementById("map"), {
        center: centerLocation,
        zoom: 8,
        mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
      });

      oneTripDetail?.likedDestination?.length > 0 &&
        oneTripDetail?.likedDestination.map((o, index) => {
          let request = {
            location: mapDiv.getCenter(),
            radius: "500",
            query: o.title,
          };
          let service = new google.maps.places.PlacesService(mapDiv);
          service.textSearch(request, callback);

          // Checks that the PlacesServiceStatus is OK, and adds a marker
          // using the place ID and location from the PlacesService.
          function callback(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              const markerDetail = document.createElement("div");
              markerDetail.className = "mapMarker";
              markerDetail.textContent = `${index + 1}`;
              let marker = new AdvancedMarkerElement({
                map: mapDiv,
                position: results[0].geometry.location,
                title: `${request.query}`,
                content: markerDetail,
              });
              marker.addListener("click", () => {
                infoWindow.close();
                infoWindow.setContent(marker.title);
                infoWindow.open(marker.map, marker);
                mapDiv.setZoom(12);
              });
            }
          }
          return service;
        });
    }
  });

  return (
    <>
      {oneTripDetail?.likedDestination &&
      oneTripDetail?.likedDestination.length > 0 ? (
        <>
          <div
            id='map'
            className='mapContainer my-3'
          ></div>
          <div className='accountDiv my-4'>
            {oneTripDetail?.likedDestination?.map((o, i) => (
              <div
                key={i}
                className='accountContainer'
                style={{ paddingLeft: "12px" }}
              >
                <div className='tripsTop'>
                  <Badge className='badge-Black'>{i + 1}</Badge>
                </div>
                <div className='flex accountWpr'>
                  <div
                    onClick={() => handleClickOnLikeActivity(i)}
                    className='acccount_text detailBox'
                    id='activity-details-likes-list'
                  >
                    <p style={{ fontSize: "14px", fontWeight: "700" }}>
                      {o.title}
                    </p>
                    <p>{oneTripDetail.tripLocation}</p>
                    {/* <p>
                      {propertyData?.[currentIndex]?.pricing
                        ? `£${propertyData?.[currentIndex]?.pricing?.summary?.fromPrice}`
                        : "£XXX"}
                    </p> */}
                  </div>
                  <div className='centerImg'>
                    <Link
                      to={o.productUrl}
                      target='_blank'
                    >
                      <button
                        style={{ width: "75px", height: "40px" }}
                        className='golbalbtn'
                        type='button'
                      >
                        Book
                      </button>
                    </Link>
                  </div>
                </div>
                <ul
                  className='flex'
                  id='likeBedge'
                >
                  <Badge
                    onClick={() => {
                      setIndex(i);
                      setShow(true);
                    }}
                    id='Who liked this?'
                    className='badge-secondary detailBox'
                  >
                    <div className='likeCount'>
                      <div className='likeThumbSmall'>
                        <img
                          src={like}
                          alt='like'
                        />
                      </div>
                      <p className='mb-0'>{o.likedBy?.length}</p>
                    </div>
                  </Badge>
                </ul>
              </div>
            ))}
          </div>
          <div className='extraSpace'></div>
        </>
      ) : (
        <h6 className='golbalText noProperty'>
          Your group hasn’t liked any activities yet.
        </h6>
      )}
      {show && (
        <LikeModal
          show={show}
          setShow={setShow}
          likedBy={oneTripDetail?.likedDestination[index]?.likedBy}
          disLikedBy={oneTripDetail?.likedDestination[index]?.disLikedBy}
          tripCode={oneTripDetail?.tripCode}
        />
      )}
      {showDetailProperty && (
        <PropertyDetails
          oneTripDetail={oneTripDetail}
          showDetailProperty={showDetailProperty}
          setShowDetailProperty={setShowDetailProperty}
          propertyData={oneTripDetail?.likedDestination[index]}
          isSwipe={false}
        />
      )}
    </>
  );
};

export default LikeProperties;
