import { createSlice } from "@reduxjs/toolkit";
import { AUTH_SLICE } from "../config/constant";

export const authSlice = createSlice({
  name: AUTH_SLICE,
  initialState: {
    //login states
    loginLoading: false,
    login: null,
    loginError: "",

    //signup states
    registerUserLoading: false,
    registerUser: null,
    registerUserError: "",

    //invited User states
    invitedUserLoading: false,
    invitedUser: null,
    invitedUserError: "",
  },
  reducers: {
    //login user
    loginRequest: (state) => {
      state.loginLoading = true;
      state.loginError = "";
    },
    loginSuccess: (state, action) => {
      state.login = action.payload;
      state.loginLoading = false;
    },
    loginError: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload;
    },
    //login user

    //register user
    registerUserRequest: (state) => {
      state.registerUserLoading = true;
      state.registerUserError = "";
    },
    registerUserSuccess: (state, action) => {
      state.registerUser = action.payload;
      state.registerUserLoading = false;
    },
    registerUserError: (state, action) => {
      state.registerUserLoading = false;
      state.registerUserError = action.payload;
    },
    //register user

    //invited user
    invitedUserRequest: (state) => {
      state.invitedUserLoading = true;
      state.invitedUserError = "";
    },

    invitedUserSuccess: (state, action) => {
      state.invitedUser = action.payload;
      state.invitedUserLoading = false;
    },

    invitedUserError: (state, action) => {
      state.invitedUserLoading = false;
      state.invitedUserError = action.payload;
    },
    //invited user
  },
});

export const {
  loginRequest,
  loginSuccess,
  loginError,
  registerUserRequest,
  registerUserSuccess,
  registerUserError,
  invitedUserRequest,
  invitedUserSuccess,
  invitedUserError,
} = authSlice.actions;

export default authSlice.reducer;
