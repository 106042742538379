import React from "react";
import { Container, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

const EmailContent = () => {
  const { registerUserLoading, registerUser, login, invitedUser } = useSelector(
    (state) => state.auth
  );
  return (
    <Container>
      <div className="wprcontainer">
        {registerUserLoading ? (
          <Spinner
            style={{
              color: "#ffc533",
            }}
            animation="border"
            variant="warning"
          />
        ) : (
          <div className="checkwpr">
            <div className="emailContainer">
              <h6 className="golbalText ">Check your emails!</h6>
              <p>
                {login
                  ? login?.email
                  : registerUser
                  ? registerUser?.email
                  : invitedUser?.email}
              </p>
              <p className="px-4 pt-4">
                We’ve sent you an email. Nearly there! Just click on the link
                inside it to {registerUser !== null ? "get started" : "log in"}.{" "}
              </p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EmailContent;
