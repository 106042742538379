// env
export const ENV_PRODUCTION = "production";
export const ENV_DEVELOPMENT = "dev";
// env

//Local Storage
export const LOCALSTORAGE_USER_AUTH_TOKEN_KEY = "user-token";
export const LOCALSTORAGE_USER_AUTH_DETAILS_KEY = "user-details";
//Local Storage

//Error Code
export const SERVER_AUTH_ERROR_STATUS_CODE = 401;
export const GENERIC_ERROR_MESSAGE =
  "Something went wrong! Please try again later.";
//Error Code

//Routes
export const LOGIN = "login";
export const SIGNUP = "signup";
export const SEND_MAIL = "thank-you";
export const USER_CHECK = "holiday";
export const USER_NAME = "holiday/name";
export const HOLIDAY_NAME = "holiday/trip-name";
export const HOLIDAY_DATES = "holiday/trip-date";
export const HOLIDAY_GROUP = "holiday/trip-members";
export const HOLIDAY_LOCATION = "holiday/trip-location";
export const MY_ACCOUNT = "account";
export const ACTIVITY = "activity";
export const INVITE_USER = "invite-friend";
export const INVITED_USER = "invite";
//Routes

//Slice Names
export const AUTH_SLICE = "auth";
export const USER_SLICE = "user";
export const TRIP_SLICE = "trip";
//Slice Names
