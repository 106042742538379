import LocalStorageService from "../helper/localstorage-services";
import UserService from "../services/userService";
import {
  addUserNameError,
  addUserNameRequest,
  addUserNameSuccess,
  oneUserError,
  oneUserRequest,
  oneUserSuccess,
  updateUserNameError,
  updateUserNameRequest,
  updateUserNameSuccess,
  userNameError,
  userNameRequest,
  userNameSuccess,
} from "../slice/userSlice";

export function getUser(user_id) {
  return (dispatch) => {
    dispatch(oneUserRequest());
    UserService.getUserById(user_id)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          LocalStorageService.loginUser(data);
          dispatch(oneUserSuccess({ ...data, message }));
        } else {
          dispatch(oneUserError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(oneUserError(error));
      });
  };
}

export function addUserNameData(payload) {
  return (dispatch) => {
    dispatch(addUserNameRequest());
    UserService.addName(payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          const userData = LocalStorageService.getLoggedInUserDetails();
          const finalData = { ...userData, name: data.name };
          LocalStorageService.loginUser(finalData);
          dispatch(addUserNameSuccess({ ...data, message }));
        } else {
          dispatch(addUserNameError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(addUserNameError(error));
      });
  };
}

export function getUserName(user_id) {
  return (dispatch) => {
    dispatch(userNameRequest());
    UserService.getName(user_id)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          dispatch(userNameSuccess({ ...data, message }));
        } else {
          dispatch(userNameError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(userNameError(error));
      });
  };
}

export function updateUserNameData(user_id, payload) {
  return (dispatch) => {
    dispatch(updateUserNameRequest());
    UserService.updateName(user_id, payload)
      .then((response) => {
        const { status, data, message, error } = response.data;
        if (status === 1) {
          const userData = LocalStorageService.getLoggedInUserDetails();
          const finalData = { ...userData, name: data.name };
          LocalStorageService.loginUser(finalData);
          dispatch(updateUserNameSuccess({ ...data, message }));
        } else {
          dispatch(updateUserNameError({ ...error, message }));
        }
      })
      .catch((error) => {
        dispatch(updateUserNameError(error));
      });
  };
}
