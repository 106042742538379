import {
  LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
  LOCALSTORAGE_USER_AUTH_TOKEN_KEY,
} from "../config/constant";

function getLoggedInUserToken() {
  const token = localStorage.getItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  return token || null;
}

function getLoggedInUserDetails() {
  const id = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY)
  );
  return id || null;
}

function loginUser(data) {
  localStorage.setItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY, data.token);
  localStorage.setItem(
    LOCALSTORAGE_USER_AUTH_DETAILS_KEY,
    JSON.stringify(data)
  );
}

function logoutUser() {
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_TOKEN_KEY);
  localStorage.removeItem(LOCALSTORAGE_USER_AUTH_DETAILS_KEY);
}

const LocalStorageService = {
  getLoggedInUserToken,
  getLoggedInUserDetails,
  loginUser,
  logoutUser,
};

export default LocalStorageService;
