import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Landing";
import ROUTE_URLS from "./config/routes";
import RouteAuthGuard from "./components/route-auth-guard/Route-Auth-Guard";
import Login from "./pages/login/Login";
import EmailContent from "./components/EmailContent";
import Signup from "./pages/signup/Signup";
import UserCheck from "./components/UserCheck";
import UserName from "./pages/trip/UserName";
import Account from "./pages/Account";
import TripName from "./pages/trip/TripName";
import TripDate from "./pages/trip/TripDate";
import TripMembers from "./pages/trip/TripMembers";
import InviteUser from "./pages/invitation/InviteUser";
import LoginInvitedUser from "./pages/invitation/LoginInvitedUser";
import NotFound from "./pages/NotFound/NotFound";
import Property from "./pages/properties/Property";
import TripLocation from "./pages/trip/TripLocation";
import { ToastContainer } from "react-toastify";
import RouteAuthGuardRestrictLoggedUser from './components/route-auth-guard/Route-Auth-Guard-Restrict-LoggedUser';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path={ROUTE_URLS.HOME}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <Home />
            </RouteAuthGuardRestrictLoggedUser>} />
        <Route
          path={ROUTE_URLS.LOGIN}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <Login />
            </RouteAuthGuardRestrictLoggedUser>
          } />
        <Route
          path={ROUTE_URLS.SEND_MAIL}
          element={
            // <RouteAuthGuardRestrictLoggedUser>
            <EmailContent />
            // </RouteAuthGuardRestrictLoggedUser>
          } />
        <Route
          path={ROUTE_URLS.SIGNUP}
          element={
            <RouteAuthGuardRestrictLoggedUser>
              <Signup />
            </RouteAuthGuardRestrictLoggedUser>
          } />
        <Route
          path={`${ROUTE_URLS.INVITED_USER}/:tripCode`}
          element={
            // <RouteAuthGuardRestrictLoggedUser>
            <LoginInvitedUser />
            // </RouteAuthGuardRestrictLoggedUser>
          }
        />
        <Route
          path={`${ROUTE_URLS.USER_CHECK}/:user_id/:tripCode?`}
          element={
            // <RouteAuthGuardRestrictLoggedUser>
            <UserCheck />
            // </RouteAuthGuardRestrictLoggedUser>
          }
        />
        <Route
          path={`${ROUTE_URLS.USER_NAME}/:tripCode?`}
          element={
            <RouteAuthGuard>
              <UserName />
            </RouteAuthGuard>
          }
        />
        <Route
          path={ROUTE_URLS.MY_ACCOUNT}
          element={
            <RouteAuthGuard>
              <Account />
            </RouteAuthGuard>
          }
        />
        <Route
          path={ROUTE_URLS.HOLIDAY_NAME}
          element={
            <RouteAuthGuard>
              <TripName />
            </RouteAuthGuard>
          }
        />
        <Route
          path={ROUTE_URLS.HOLIDAY_DATES}
          element={
            <RouteAuthGuard>
              <TripDate />
            </RouteAuthGuard>
          }
        />
        <Route
          path={`${ROUTE_URLS.HOLIDAY_LOCATION}/:tripCode?`}
          element={
            <RouteAuthGuard>
              <TripLocation />
            </RouteAuthGuard>
          }
        />
        <Route
          path={ROUTE_URLS.HOLIDAY_GROUP}
          element={
            <RouteAuthGuard>
              <TripMembers />
            </RouteAuthGuard>
          }
        />
        <Route
          path={`${ROUTE_URLS.INVITE_USER}/:tripCode`}
          element={
            <RouteAuthGuard>
              <InviteUser />
            </RouteAuthGuard>
          }
        />
        <Route
          path={`${ROUTE_URLS.ACTIVITY}/:tripCode`}
          element={
            <RouteAuthGuard>
              <Property />
            </RouteAuthGuard>
          }
        />
        <Route path={`*`} element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
