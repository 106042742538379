import { post } from ".";

const URI = "/user";

const login = (payload) => {
  const URL = `${URI}/loginUser`;
  return post(URL, payload);
};

const signup = (payload) => {
  const URL = `${URI}/addUser`;
  return post(URL, payload);
};

const invitedUser = (payload) => {
  const URL = `${URI}/inviteUser`;
  return post(URL, payload);
};

const AuthService = {
  login,
  signup,
  invitedUser,
};

export default AuthService;
